<template>
  <div class="container kuan">
    <div class="main">
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div>
      <top-timer></top-timer>
      <div class="dr_row">
        <h3>文件路径：</h3>
        <input type="file">
        <el-button type="success" size="small">提交</el-button>
      </div>

      <div class="dr_row">
        <h3>格式A：</h3>
        号码号码号码
        <h3>格式B</h3>
        号码=金额，号码=金额，号码=金额 (逗号也可以用空格代表)
        <h3>四字现格式：</h3>
        例如1234现=a1234
      </div>
      <div class="dr_row">
        <h3>说明：</h3>
        由于各会员使用的（txt文件）的格式不一样，如果不符合网站上要求的格式，有可能导入到网站（没有下注之前）的号码内容和自己（txt文件）里号码内容不一致，操作时请认真检查，如果出现内容不一致，请不要下注。
      </div>
      <h2 class="h2">文件明细</h2>
      <p class="des"> <span class="font_bold">说明</span> ：如果导入的文件里有相同号码，下注成功后会做合金额的处理，请核对下注之前的总金额和下注成功后小票处的总金额是否一致</p>
      <p class="des"> <span class="font_bold">比如：</span> 文件里有1234,1234各5元，2笔单。下注成功后合金额变成1234=10元，1笔单。</p>

    </div>

  </div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import mixins from "../mixins/mainMixins";
export default {
  name: "txtdr",
  props: [""],
  mixins: [mixins],
  components: {
    yushe,
    topTimer,
  },
  data() {
    return {
      // 长龙 tab
      changelongActive: "1",
      baishige: "1",
      // 连续开  连续未开 tab
      kaiActive: "1",
      rowsData: [],
      baishi: { title: "佰拾定位", arr: [] },
      baige: { title: "佰个定位", arr: [] },
      shige: { title: "拾个定位", arr: [] },
    };
  },
  watch: {
    pan(val) {
      this.baishige = 1;
    },
  },

  created() {},

  methods: {},
};
</script>
<style  scoped>
p.des {
  color: red;
  font-size: 15px;
}
.skin-blue .h2 {
  background: #2161b3;
}
.skin-red .h2 {
  background: #c2455c;
}
.skin-brown .h2 {
  background: #a18361;
}
.h2 {
  margin-top: 10px;
  height: 30px;
  line-height: 30px;
  color: #fff;
  text-align: center;
  font-size: 15px;
}
.dr_row h3 {
  min-width: 50px;
  margin-left: 8px;
}
.dr_row {
  display: flex;
  align-items: center;
  height: 40px;
  border-bottom: 1px solid #e4e4e4;
}
.listmain > .data > ul.custom_ul {
  width: 100%;
  height: 520px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-flow: none;
}

.listmain .data ul li {
  width: 20%;
  height: 26px;
}
</style>